import {defineStore} from 'pinia'
import {Ref, ref} from 'vue'
import { ApiDefaultResponse, fetchGET } from '@/views/mixins/PredoriFetch'
import { IUser } from "@/stores/userInfoStore";
import { showSnackBarItem, snackBarHelper, SnackbarType } from "@/views/helpers/SnackbarHelper";

export const useInfoCenterMessageStore = defineStore('info_center_message', () => {
	const messages: Ref<IMessage[] | null> = ref(null)
	const filteredMessages: Ref<IMessage[] | null> = ref(null)
	const activeFilter: Ref<messageFilter> = ref('All')


	async function getMessages() {
		if (messages.value !== null) {
			// setMessageFilter(activeFilter.value)
			return messages.value
		}
		const newMessages = await fetchMessages()
		messages.value = newMessages

		// setMessageFilter(activeFilter.value)
		return newMessages
	}

	async function getUnreadMessagesCount() {
		const messages = await getMessages()
		if (messages === null) {
			return 0
		}
		return  messages.filter((m) => !m.is_read && !m.is_deleted).length
	}

	function setMessageFilter(filter: messageFilter) {
		activeFilter.value = filter
		return setFilteredMessages(filter)
	}

	async function markMessageAsRead( message: IMessage) :Promise<IMessage | null> {
		const url = `/spa/api/info_center/message_mark_as_read/${message.id}`
		const json = await fetchGET<IMessageResponse>({url: url})
		if (json === undefined) {
			return null
		}

		const updatedMessage = parseMessage(json.message)
		updateMessageInCaches(updatedMessage)

		updateUnreadCounter()
		return updatedMessage
	}

	/*
	 * update the badge counter in the navbar.
	 * this is for now in a hacky way. - when header is migrated to spa subscribe to the store and use the unread results to update
	 */
	function updateUnreadCounter() {
		if (messages.value === null) {
			return
		}
		const unreadMessages = messages.value.filter((m) => !m.is_read).length
		const  badgeElement = document.querySelector('.pd-info-center-badge');
		if (!badgeElement) {
			return;
		}
		badgeElement.textContent = unreadMessages > 0 ? unreadMessages.toString() : '';
	}

	async function deleteMessage( message: IMessage) :Promise<IMessage | null> {
		const url = `/spa/api/info_center/message_delete/${message.id}`
		const json = await fetchGET<IMessageResponse>({url: url})
		if (json === undefined) {
			return null
		}
		const updatedMessage = parseMessage(json.message)
		updateMessageInCaches(updatedMessage)

		return updatedMessage
	}

	async function undeleteMessage( message: IMessage) :Promise<IMessage | null> {
		const url = `/spa/api/info_center/message_undelete/${message.id}`
		const json = await fetchGET<IMessageResponse>({url: url})
		if (json === undefined) {
			return null
		}
		const updatedMessage = parseMessage(json.message)
		updateMessageInCaches(updatedMessage)

		return updatedMessage
	}

	async function deleteMessageFinally( message: IMessage) {
		const url = `/spa/api/info_center/message_delete_final/${message.id}`
		const json = await fetchGET<ApiDefaultResponse>({url: url})
		if (json === undefined) {
			return null
		}
		if (json.status === 'error') {
			showSnackBarItem(snackBarHelper('Error', 'An error ocurred while deleting the message', SnackbarType.ERROR));
		}
		if (messages.value === null) {
			return null
		}
		messages.value = messages.value?.filter((m) => m.id !== message.id)
		setFilteredMessages(activeFilter.value)
		updateUnreadCounter()
	}

	return {getMessages, setMessageFilter, filteredMessages, getUnreadMessagesCount, markMessageAsRead, deleteMessage, undeleteMessage, deleteMessageFinally}

	function parseMessage(message: IMessage): IMessage {
		return {
			id: message.id,
			user: message.user,
			headline: message.headline,
			message: message.message,
			type: messageTypeToName(message.type),
			is_read: message.is_read,
			is_deleted: message.is_deleted,
			created_at: renderDate(new Date(message.created_at)),
			updated_at: renderDate(new Date(message.updated_at))
		}
	}

	function updateMessageInCaches(message: IMessage) {
		if (messages.value === null) {
			return
		}
		const indexMessages = messages.value.findIndex((m) => m.id === message.id)
		messages.value[indexMessages] = message

		if (filteredMessages.value === null) {
			return
		}
		setFilteredMessages(activeFilter.value)
	}

	function setFilteredMessages(filter: messageFilter) {
		if (!messages.value) {
			console.warn('Messages are null. Ensure getMessages() is called before setting the filter.');
			return null;
		}


		filteredMessages.value = messages.value.filter((message) => {
			// Filter conditions based on filter value
			if (filter === 'All') {
				return !message.is_deleted
			}
			if (filter === 'Deleted') {
				return message.is_deleted
			}
			return message.type === filter && !message.is_deleted
		});

		return filteredMessages.value;
	}

	async function fetchMessages()  {
		const url = '/spa/api/info_center/list'
		const json = await fetchGET<IMessagesResponse>({url: url})
		if (json === undefined) {
			return null
		}

		const parsedMessages: IMessage[] = json.messages.map((message) => {
			return parseMessage(message)
		})
		return parsedMessages
	}

	function renderDate(date: Date): string {
		if (date === null) {
			return "Unknown date";
		}

		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');

		// Return the formatted date
		return `${day}-${month}-${year} ${hours}:${minutes}`;
	}
})

function  messageTypeToName(type): nullableMessageType  {
	switch (type) {
		case 1:
			return 'Monitoring'
		case 2:
			return 'Account'
		case 3:
			return 'Newsletter'
		default:
			return null
	}

}

export type messageType = 'Monitoring' | 'Account' | 'Newsletter'
export type nullableMessageType = messageType | null
export type messageFilter = 'All' |  'Monitoring' | 'Account' | 'Newsletter' | 'Deleted'

export interface IMessage {
	id: number
	user: IUser,
	headline: string
	message: string
	type: nullableMessageType
	is_read: boolean
	is_deleted: boolean
	created_at: string
	updated_at: string
}

interface IMessagesResponse {
	messages: IMessage[]
	status: string
}


interface IMessageResponse {
	message: IMessage
	status: string
}
