import {defineStore} from 'pinia'
import { useUserStore } from "@/stores/userInfoStore";

export interface IUser {
	id: number;
	company_id: number | null;
	first_name: string;
	last_name: string;
	email: string;
	is_active: boolean;
	is_admin_user: boolean;
}

export type TPermission =
	| 'DOCUMENT_MONITORING'
	| 'FTO'
	| 'INVALIDATION'
	| 'PATENTABILITY'
	| 'SINGLE_DOCUMENT_SEARCH'
	| 'STATE_OF_THE_ART'
	| 'TECHNOLOGY_MONITORING'
	| 'ULTRA_QUICK'
	| 'BETA_TESTER'
	| 'PREDORIAN'
	| 'TAGS'
	| 'PREDORI_X'
	| 'TEST_FALSE'
	| 'MONITORING'
	| 'WISH_LIST'
	| 'BUG_REPORT'
	| 'COMPANY_ADMIN'
	| 'INFO_CENTER'

export type IPermissions = Record<TPermission, boolean>;

export const usePermissionStore = defineStore('permission_store', () => {

	const userStore = useUserStore();



	async function userHasPermission(permission: TPermission) : Promise<boolean> {
		const user = await userStore.getUserWithDetails();
		if (!user) {
			return false
		}

		return user.permissions[permission]
	}

	async function getPermissionsList():  Promise<IPermissions> {
		const permissions: IPermissions = {} as IPermissions;

		// if (permissionList.value) {
		// 	return permissionList.value;
		// }

	// Iterate over all TPermission values and set default values
		const allPermissions: TPermission[] = [
			'DOCUMENT_MONITORING',
			'FTO',
			'INVALIDATION',
			'PATENTABILITY',
			'SINGLE_DOCUMENT_SEARCH',
			'STATE_OF_THE_ART',
			'TECHNOLOGY_MONITORING',
			'ULTRA_QUICK',
			'BETA_TESTER',
			'PREDORIAN',
			'TAGS',
			'PREDORI_X',
			'TEST_FALSE',
			'MONITORING',
			'WISH_LIST',
			'BUG_REPORT',
			'COMPANY_ADMIN',
			'INFO_CENTER'
		];
		// /call user store to ensure user is loaded before the Promise All Loop woould start it multiple times
		const _ = await userStore.getUserWithDetails();

		// Populate the permissions object
		await Promise.all(
			allPermissions.map(async (permission) => {
				permissions[permission] = await userHasPermission(permission);
			})
		);
		// permissionList.value = permissions
		return permissions;
	}

	return { userHasPermission, getPermissionsList };
})
