
import { defineComponent } from 'vue';
// import PageLeaveWarningComponentt from "@/components/PageLeaveWarningComponent.vue";
import AppMainMenu from "@/components/layout/AppMainMenu.vue";
import OverlayComponentRenderer from "@/components/OverlayComponentRenderer.vue";
import AppHeader from "@/components/layout/AppHeader.vue";  // Import the component globally

export default defineComponent({
	components: {
		AppHeader,
		OverlayComponentRenderer,
		AppMainMenu,
	}
});
