
import {defineAsyncComponent, defineComponent, nextTick} from 'vue';
import {renderNextSnackbar} from "@/views/helpers/SnackbarHelper";

export default defineComponent({
  name: "OverlayComponentRenderer",
  components: {
    // define all components that are used by the overlay renderer as asyncComponents so that they can be loaded and only get loaded when rendered
    HelloComponent: defineAsyncComponent(() => import("@/components/assessment_center/HelloWorldComponent.vue")),
    CancelSearch: defineAsyncComponent(() => import("@/components/overlays/CancelSearch.vue")),
    MonitoringInputOverlay: defineAsyncComponent(() => import("@/components/overlays/MonitoringInputOverlay.vue")),
    DocMonitoringFieldContainerOverlay: defineAsyncComponent(() => import("@/components/overlays/DocMonitoringFieldContainerOverlay.vue")),
    DocMonitoringFieldContainerOverlaySuccess: defineAsyncComponent(() => import("@/components/overlays/DocMonitoringFieldContainerOverlaySuccess.vue")),
    SDOCInputOverlay: defineAsyncComponent(() => import("@/components/overlays/SDOCInputOverlay.vue")),
    AddToCollectionOverlay: defineAsyncComponent(() => import("@/components/overlays/AddToCollectionOverlay.vue")),
    AddToCollectionOverlaySuccess: defineAsyncComponent(() => import("@/components/overlays/AddToCollectionOverlaySuccess.vue")),
    SaveSearchSuccessOverlay: defineAsyncComponent(() => import("@/components/overlays/SaveSearchSuccessOverlay.vue")),
    MonitoringInProgressOverlay: defineAsyncComponent(() => import("@/components/overlays/MonitoringInProgressOverlay.vue")),
    RemoveResultFromCollectionOverlay: defineAsyncComponent(() => import("@/components/overlays/RemoveResultFromCollectionOverlay.vue")),
    RemoveResultFromCollectionOverlaySuccess: defineAsyncComponent(() => import("@/components/overlays/RemoveResultFromCollectionOverlaySuccess.vue")),
		SuccessOverlay: defineAsyncComponent(() => import("@/components/overlays/SuccessOverlay.vue")),
		FilterMonitoringResultsByIterationsOverlay: defineAsyncComponent(() => import("@/components/overlays/FilterMonitoringResultsByIterationsOverlay.vue")),
		InfoCenterOverlay: defineAsyncComponent(() => import("@/components/overlays/InfoCenterOverlay.vue")),
		InviteUserOverlay: defineAsyncComponent(() => import("@/components/overlays/company_onboarding_and_management/InviteUserOverlay.vue")),

    EditUserOverlay: defineAsyncComponent(() => import("@/components/overlays/company_onboarding_and_management/EditUserOverlay.vue")),
    DisableUserOverlay: defineAsyncComponent(() => import("@/components/overlays/company_onboarding_and_management/DisableUserOverlay.vue")),
    EnableUserOverlay: defineAsyncComponent(() => import("@/components/overlays/company_onboarding_and_management/EnableUserOverlay.vue")),
		BugReportOverlay: defineAsyncComponent(() => import("@/components/overlays/BugReportOverlay.vue")),
		UserSettingOverlay: defineAsyncComponent(() => import("@/components/overlays/UserSettingOverlay.vue")),
		WishlistOverlay: defineAsyncComponent(() => import("@/components/overlays/WishlistOverlay.vue")),
    RenderSnackbarOverlay: defineAsyncComponent(() => import("@/components/overlays/RenderSnackbarOverlay.vue")),
		TeamDeleteOverlay: defineAsyncComponent(() => import("@/components/management/team/TeamDeleteOverlay.vue")),
		TeamShareWithOverlay: defineAsyncComponent(() => import("@/components/management/team/TeamShareWithOverlay.vue")),
		TeamDeleteMemberOverlay: defineAsyncComponent(() => import("@/components/management/team/TeamDeleteMemberOverlay.vue")),
		ProjectShareWithOverlay: defineAsyncComponent(() => import("@/components/management/project/ProjectShareWithOverlay.vue")),
		FolderShareWithOverlay: defineAsyncComponent(() => import("@/components/management/project/FolderShareWithOverlay.vue")),
		MonitoringShareWithOverlay: defineAsyncComponent(() => import("@/components/management/project/MonitoringShareWithOverlay.vue")),
		AcceptInvitationOverlay: defineAsyncComponent(() => import("@/components/overlays/user_team/AcceptInvitationOverlay.vue")),
		PageLeaveWarningOverlay: defineAsyncComponent(() => import("@/components/overlays/PageLeaveWarningOverlay.vue")),
  },
  props: [],

  data: function () {
    return {
      componentToRender: '',
      paramContainer: {},
      active: false,
      noMaxWidthComponents: ['DocMonitoringFieldContainerOverlay','InfoCenterOverlay'],
			noAutoHeightComponents: ['InfoCenterOverlay', 'UserSettingOverlay'],
			openSince: new Date()
    }
  },
	created: function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$eventBus.$on('show-component-overlay', this.show);

    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        // The escape key was pressed
        // Perform your action here
        this.close()
      }
    });

		// close on click outside of div
		document.addEventListener('click', (e) => {
				// Later, check if openSince is at least one second open
				const currentTime = new Date();
				const timeDifference = currentTime.getTime() - this.openSince.getTime();  // timeDifference in milliseconds
				// we want to ensure the overlay iss open at least half a second before the outside click is closing it to prevent  mouseup events to close it
				if (timeDifference <= 200) {  // 1000 milliseconds = 1 second
					return
				}

				const domNode = e.target as HTMLElement;

				if (domNode.closest(".overlay-container") || domNode.classList.contains("overlay-container")) {
					return;
				}
				this.close();
			});
    // if we have a message on the SnackBar Stack, render it.
    renderNextSnackbar();
  },
  methods: {
    componentHasMaxwidth(component) {
      return !this.noMaxWidthComponents.includes(component);
    },
		componentHasAutoHeight(component) {
			return !this.noAutoHeightComponents.includes(component);
		},
    show(componentToRender, params) {
      this.componentToRender = componentToRender;
      this.paramContainer = params;
      this.active = true;
			this.openSince = new Date();
    },
    close() {
      this.active = false;
      this.paramContainer = {};

      switch(this.componentToRender) {
        case "RenderSnackbarOverlay":
          // process the stack if we have a snackbar to render
          this.componentToRender = '';
          nextTick(() => {
            renderNextSnackbar();
          });

          break;
      }
      this.componentToRender = '';
    }
  }
});
