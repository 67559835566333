import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "sidebar-entrys-container" }
const _hoisted_2 = {
  key: 0,
  class: "sidebar-entry sidebar-blue"
}
const _hoisted_3 = { class: "item-container" }
const _hoisted_4 = { class: "item-label" }
const _hoisted_5 = ["onClick", "id", "href"]
const _hoisted_6 = { class: "item-label" }
const _hoisted_7 = { class: "item-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: step }, [
        (step.type=='menu_section')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(step.title), 1)
              ])
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.renderAsLink(step))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    onClick: ($event: any) => (_ctx.openMenuOrTriggerLink($event, step.step_url)),
                    id: 'right_nav' + step.id,
                    href: step.step_url,
                    class: _normalizeClass(["pd-allow-pageleave sidebar-entry sidebar-nav-item", {'pd-post-save-before-leave':_ctx.isFormDirty(),
                                        'nine-entrys': (_ctx.navbarStates.search_type=='Technology Monitoring'),
                                        'ten-entrys': (_ctx.navbarStates.search_type=='FTO'),
                                        'pd-post-save-before-leave': _ctx.hasPostSaveBeforePageLeaveClass}])
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["item-container", {'active':_ctx.stepUrlsEqual(step.step_path, _ctx.navbarStates.at_step_search),
                                            'navitem-status-current': _ctx.isCurrentStep(step),
                                            'navitem-status-green': _ctx.isFinishedStep(step) }])
                    }, [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(step.label), 1)
                    ], 2)
                  ], 10, _hoisted_5))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass(["sidebar-entry sidebar-nav-item", {'nine-entrys': (_ctx.navbarStates.search_type=='Technology Monitoring'), 'ten-entrys': (_ctx.navbarStates.search_type=='FTO') }])
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["item-container", {'active':_ctx.stepUrlsEqual(step.step_path, _ctx.navbarStates.at_step_search),
                                            'navitem-status-current': _ctx.isCurrentStep(step),
                                            'navitem-status-green': _ctx.isFinishedStep(step) }])
                    }, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(step.label), 1)
                    ], 2)
                  ], 2))
            ], 64))
      ], 64))
    }), 128))
  ]))
}