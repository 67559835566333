import {app} from "@/main";

export enum SnackbarType {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
}


export type SnackbarData = {
    title: string;
    text: string;
    type: SnackbarType;
};

export function snackBarHelper(title: string, text: string, type: SnackbarType): SnackbarData {
    return {
        title: title,
        text: text,
        type: type,
    }
}

export function snackBarHelperFromBackend(snackbarData) {
    return snackBarHelper(snackbarData['title'], snackbarData['text'], snackbarData['type']);
}

// addds a snackbaritem to a stack that is rendered tthe next time a OverlayComponentRenderer is rendered (this provides an on the next page functionality)
export function pushSnackBarItem(snackbarData: SnackbarData) {
    app.config.globalProperties.$snackbarStack.push(snackbarData);
}

// renders snackbar emidiately without stack cacheing
export function showSnackBarItem(snackbarData: SnackbarData) {
    app.config.globalProperties.$eventBus.$emit('show-component-overlay', "RenderSnackbarOverlay", snackbarData);
}

export function shiftSnackBarItem() {
    return app.config.globalProperties.$snackbarStack.shift();
}

export function renderNextSnackbar() {
    if (app.config.globalProperties.$snackbarStack.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        app.config.globalProperties.$eventBus.$emit('show-component-overlay', "RenderSnackbarOverlay", shiftSnackBarItem());
    }
}
